import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Emailsent = (props) => {
  const data = JSON.parse(localStorage.getItem("data"));
  const applicant_id = data ? data.applicant_id : null;
  const navigate = useNavigate();

  const handleNoButtonClick = () => {
    axios
      .post(`https://api.xhorizons-dev.lexxtechnologies.com/cancelgen/${applicant_id}`)
      .then((response) => {
        console.log("Document generation cancelled successfully:", response.data);
        navigate("/");
      })
      .catch((error) => {
        console.error("Error cancelling document generation:", error);
      });
  };

  const handleYesButtonClick = () => {
    axios
      .post(`https://api.xhorizons-dev.lexxtechnologies.com/sendemp/${applicant_id}`)
      .then((response) => {
        console.log("Document sent successfully:", response.data);
        navigate("/emailsent");
      })
      .catch((error) => {
        console.error("Error sending document:", error);
      });
  };

  return (
    <div className="docemailpopup">
      <div className="docsentpop">
        <div className="docmail">
          <h1>
            All the documents are successfully generated.
            <br />
            Do you want to send them in an Email to the Applicant?
          </h1>
        </div>
        <div className="alert-message-button">
          <button className="okay-popup" onClick={handleNoButtonClick}>No</button>
          <button className="okay-popup" onClick={handleYesButtonClick}>Yes</button>
        </div>
      </div>
    </div>
  );
};

export default Emailsent;
