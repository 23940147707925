import React from "react";
import { Table } from "semantic-ui-react";

const Rpl = () => {
  return (
    <div>
      <Table unstackable className="table-wrapper">
        <thead>
          <tr style={{ backgroundColor: "#0971ce", color: "black" }}>
            <th colSpan="2" className="mandate">
              Required Documents
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                <li>Personal Documents</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                <li>Educational Documents for RPL</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }} >
                <li>RPL Reference Form</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                <li>Employment Sheet</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default Rpl;
