import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import "./css/lexx.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import KeycloakUserService from "./components/login/KeycloakUserService";

const renderApp = () =>
  ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.getElementById("root")
  );

KeycloakUserService.initKeycloak(renderApp);
reportWebVitals();
