import React, { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PopupMessage from "./PopupMessage";
import "../Generate/gen.css";

const GenerateField = () => {
  const data = JSON.parse(localStorage.getItem("data"));
  const applicant_id = data ? data.applicant_id : null;
  const navigate = useNavigate();
  const location = useLocation();
  
  const initialEmpFields = JSON.parse(localStorage.getItem("empFields"));
  const [state, setState] = useState(location.state || initialEmpFields);
  const [dropdownValues, setDropdownValues] = useState(Array(state?.employment_records?.length).fill(''));
  const [selectedCheckboxIndex, setSelectedCheckboxIndex] = useState(null);
  const [popupMessage, setPopupMessage] = useState(null);

  useEffect(() => {
    if (!state) {
      const savedState = JSON.parse(localStorage.getItem("empFields"));
      setState(savedState);
    }
  }, [state]);

  const handleGenerate = async (index, record) => {
    if (selectedCheckboxIndex !== index) {
      console.log("Checkbox is not checked for index:", index);
      return;
    }

    const selectedValue = dropdownValues[index];
    const option = mapDropdownValue(selectedValue);
    const queryString = new URLSearchParams({
      option,
      employment_no: record.employment_record_number,
    }).toString();

    try {
      const response = await fetch(`https://api.xhorizons-dev.lexxtechnologies.com/generate/${applicant_id}?${queryString}`, {
        method: 'GET',
        headers: { 'Accept': 'application/json' },
      });

      if (response.ok) {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/pdf')) {
          localStorage.setItem("empFields", JSON.stringify(state));
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          navigate(`/viewer?url=${encodeURIComponent(url)}`);
        } else {
          setPopupMessage("Already generated for this record.");
        }
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const mapDropdownValue = (selectedValue) => {
    const mapping = {
      'Employment_Evidence': 'Employment_Evidence',
      'Affidavit': 'Affidavit',
      'Statutaory_Declaration': 'Statutaory_Declaration',
    };
    return mapping[selectedValue] || '';
  };

  const handleDropdownChange = (event, index) => {
    const selectedValue = event.target.value;
    setDropdownValues(prevValues => {
      const newValues = [...prevValues];
      newValues[index] = selectedValue;
      return newValues;
    });
  };

  const handleCheckboxChange = (index) => {
    setSelectedCheckboxIndex(index);
  };

  const renderPopupMessage = () => (
    popupMessage ? (
      <PopupMessage message={popupMessage} onClose={() => setPopupMessage(null)} />
    ) : null
  );

  const renderTableRows = () => (
    state?.employment_records?.map((record, index) => (
      <Table.Row key={index}>
        <Table.Cell>
          <input 
            type="checkbox" 
            id={`checkbox_${index}`} 
            checked={selectedCheckboxIndex === index} 
            onChange={() => handleCheckboxChange(index)} 
          />
        </Table.Cell>
        <Table.Cell>
          <div className="table-row-item">
            <textarea value={record.employment_record} readOnly />
          </div>
        </Table.Cell>
        <Table.Cell>
          <div className="table-row-item">
            <select
              className="dropdown"
              onChange={(event) => handleDropdownChange(event, index)}
              value={dropdownValues[index]}
              required
            >
              <option value="">select</option>
              <option value="Employment_Evidence">Employment Reference Letter</option>
              <option value="Affidavit">Affidavit</option>
              <option value="Statutaory_Declaration">Statutory Declaration</option>
            </select>
          </div>
        </Table.Cell>
        <Table.Cell>
          <div className="button-ref">
            <button onClick={() => handleGenerate(index, record)} className="ref-colo">Generate</button>
          </div>
        </Table.Cell>
      </Table.Row>
    ))
  );

  return (
    <>
      {renderPopupMessage()}
      <div className="gen-field">
        <div className="gen-header">Generate Employment Reference</div>
        <div className="tableWrap">
          <Table celled unstackable style={{ border: "none" }} className="table-wrapper">
            <Table.Header>
              <Table.Row style={{ position: "sticky", top: "0", zIndex: "1" }}>
                <Table.HeaderCell>Select</Table.HeaderCell>
                <Table.HeaderCell className="table-header">Employment</Table.HeaderCell>
                <Table.HeaderCell className="table-header">Employment Reference</Table.HeaderCell>
                <Table.HeaderCell className="table-header"></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {renderTableRows()}
            </Table.Body>
          </Table>
        </div>
        <div className="button-previous-form">
          <Link to={"/"}>
            <button onClick={() => localStorage.removeItem("empFields")} className="FormSubmitbt" type="button" disabled style={{ filter: "blur(1px)", backgroundColor: "grey" }}>
              Back
            </button>
          </Link>
          <button onClick={() => navigate("/alertmessage")} className="FormSubmitbt" type="button">
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default GenerateField;
