import React, { useRef, useEffect, useState } from "react";
import { useStatus } from "../../statusContext";
import "semantic-ui-css/semantic.min.css";
import { Form, Grid, Popup, Input } from "semantic-ui-react";
import delete_icon from "../../images/delete.png";
import { Dropdown, Table, Image } from "semantic-ui-react";
import "../../css/index.css";
import "../../css/lexx.css";
import { Oval } from "react-loader-spinner";

const options = [
  { key: 1, text: "Form Filled", value: "Form Filled" },
  { key: 2, text: "Aborted", value: "Aborted" },
  { key: 3, text: "Processing", value: "Processing" },
  { key: 4, text: "Not a Resume", value: "Not a Resume" },
  { key: 5, text: "Error", value: "Error" },
  { key: 6, text: "Assessed", value: "Assessed" },
  { key: 7, text: "Email Sent", value: "Email Sent" },
  { key: 8, text: "Documents Uploaded", value: "Documents Uploaded" },
  { key: 9, text: "Employment Reference Generated", value: "Employment Reference Generated" },
  { key: 10, text: "Employment Reference Sent", value: "Employment Reference Sent" },
  { key: 11, text: "Employment Generation Cancelled", value: "Employment Generation Cancelled" },
  { key: 12, text: "Employment Reference Uploaded", value: "Employment Reference Uploaded" },
  { key: 13, text: "Validation Successful", value: "Validation Successful" },
  { key: 14, text: "Validation Failed", value: "Validation Failed" },
  { key: 15, text: "Validation Failed Email Sent", value: "Validation Failed Email Sent" },
  { key: 16, text: "Failed Personal Documents Uploaded", value: "Failed Personal Documents Uploaded" },
  { key: 17, text: "Failed Employment Reference Uploaded", value: "Failed Employment Reference Uploaded" },
  { key: 18, text: "Failed Documents Uploaded", value: "Failed Documents Uploaded" },
  { key: 19, text: "Downloaded", value: "Downloaded" },
];

const AWAITING_REVIEW = {
  color: "white",
  backgroundColor: "#0971ce",
  borderRadius: "5px",
  padding: "5px",
};

function Documents(props) {
  const [fileStore, setFileStore] = useState([]);
  const { docName, dashStatus } = useStatus();
  const [checklistData, setChecklistData] = useState({ data: null });
  const [disableChecklist, setDisableChecklist] = useState(checklistData.data === null);
  const [disablegenEmp, setDisablegenEmp] = useState(checklistData.data === null);
  const [disableCollect, setDisableCollect] = useState(checklistData.data === null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterValue, setFilterValue] = useState("");

  props.setDisableChecklistbtn(disableChecklist);
  props.setDisablegenEmpbtn(disablegenEmp);
  props.setDisableCollectEmpbtn(disableCollect);

  const updateenablechecklist = (item) => {
    if (item.applicant_id === checklistData.data?.applicant_id) {
      setChecklistData({ ...checklistData, data: null });
      localStorage.clear("data");
      setDisableChecklist(true);
    } else {
      setChecklistData({ ...checklistData, data: item });
      localStorage.setItem("data", JSON.stringify(item));
      setDisableChecklist(false);
    }

    if (item.status !== "Assessed" && !(item.status === "Email Sent" && ["TG", "Skills", "PAS", "RPL"].includes(item.recommended_pathway))) {
      setDisableChecklist(true);
    }
  };

  const updateenablegenEmp = (item) => {
    if (item.applicant_id === checklistData.data?.applicant_id) {
      setChecklistData({ ...checklistData, data: null });
      localStorage.removeItem("data");
      setDisablegenEmp(true);
    } else {
      setChecklistData({ ...checklistData, data: item });
      localStorage.setItem("data", JSON.stringify(item));
      setDisablegenEmp(false);
    }

    if (item.status !== "Documents Uploaded" || item.recommended_pathway === "TG") {
      setDisablegenEmp(true);
    }
  };

  const updateenablecollect = (item) => {
    if (item.applicant_id === checklistData.data?.applicant_id) {
      setChecklistData({ ...checklistData, data: null });
      localStorage.clear("data");
      setDisableCollect(true);
    } else {
      setChecklistData({ ...checklistData, data: item });
      localStorage.setItem("data", JSON.stringify(item));
      if (
        (item.recommended_pathway === "TG" && item.status === "Documents Uploaded") ||
        item.status === "Employment Reference Uploaded" ||
        item.status === "Failed Documents Uploaded"
      ) {
        setDisableCollect(false);
      } else {
        setDisableCollect(true);
      }
    }
  };

  const handledeleted = async (applicant_id) => {
    try {
      const confirmed = window.confirm("Are you sure you want to delete?");
      if (confirmed) {
        const url = `https://api.xhorizons-dev.lexxtechnologies.com/del/${applicant_id}`;
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const json = await response.json();
          if (json.result) {
            alert(json.result);
            connectWebSocket();
          } else {
            alert("An error occurred during deletion. Please check server logs.");
          }
        } else {
          alert(`Deletion failed. HTTP Status: ${response.status}`);
        }
      }
    } catch (error) {
      console.log(error);
      alert("An error occurred during deletion. Please check the console for details.");
    }
  };

  const wsRef = useRef(null);

  const connectWebSocket = () => {
    const newUrl = `wss://api.xhorizons-dev.lexxtechnologies.com/dashboard?filter=${filterValue}&search=${searchTerm}`;

    if (wsRef.current) {
      wsRef.current.close();
    }

    const ws = new WebSocket(newUrl);
    wsRef.current = ws;

    ws.onopen = () => {
      console.log('WebSocket connection opened.');
    };

    ws.onmessage = (event) => {
      console.log('WebSocket message received:', event.data);
      setFileStore(JSON.parse(event.data));
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed.');
    };
  };

  useEffect(() => {
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    connectWebSocket();
  }, [searchTerm, filterValue]);

  return (
    <>
      {console.log(fileStore)}

      <div className="session-summary documentlist document-table">
        <div className="search-dropdown">
          <Form>
            <Grid padded>
              <Grid.Row stretched>
                <Grid.Column className="searchsolution" width={13}>
                  <Form.Field>
                    <Input
                      style={{
                        fontSize: "14px",
                        background: "#f2f7fb",
                        height: "40px",
                        textAlign: "center",
                      }}
                      icon="search"
                      iconPosition="left"
                      placeholder="SEARCH"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column className="searchsolutionstatus">
                  <Dropdown
                    className="statusdropdown"
                    clearable
                    options={options}
                    selection
                    button
                    placeholder="Status"
                    value={filterValue}
                    onChange={(e, { value }) => setFilterValue(value)}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </div>

        <div
          style={{
            borderBottom: "1px solid #BDBDBD",
            display: "flex",
            flexDirection: "row",
            fontWeight: "bold",
            marginBottom: "20px",
            width: "100%",
            cursor: "pointer",
            overflowX: "auto",
          }}
        >
          <div style={{ padding: "5px" }}>
            <span>My Resumes</span>
          </div>
          <div style={{ padding: "5px", paddingLeft: "30px" }}>
            <span>All Resumes</span>
          </div>
        </div>
      </div>

      <div className="tableWrap">
        <Table
          celled
          unstackable
          style={{ border: "none" }}
          className="table-wrapper"
        >
          <Table.Header>
            <Table.Row style={{ position: "sticky", top: "0", zIndex: "1" }}>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell className="table-header">
                Assessed On
              </Table.HeaderCell>
              <Table.HeaderCell className="table-header">
                Assessed Occupation
              </Table.HeaderCell>
              <Table.HeaderCell className="table-header">
                Assessed for
              </Table.HeaderCell>
              <Table.HeaderCell className="table-header">
                Recommended Pathway
              </Table.HeaderCell>
              <Table.HeaderCell className="table-header">
                Status
              </Table.HeaderCell>
              <Table.HeaderCell className="table-header">
                Action
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {dashStatus ? (
              <Table.Row key={-1}>
                <Table.Cell>
                  <input type="checkbox" />
                  <a href={`/resume/${docName}`}>{docName}</a>
                </Table.Cell>
                <Table.Cell>
                  <div>
                    <span style={AWAITING_REVIEW}>In Process</span>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div></div>
                </Table.Cell>
              </Table.Row>
            ) : (
              <Table.Row></Table.Row>
            )}
            {fileStore.dashboard ? (
              fileStore.dashboard.map((item, index) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    <input
                      type="checkbox"
                      id="check"
                      checked={checklistData.data?.applicant_id === item.applicant_id}
                      onChange={(e) => {
                        updateenablechecklist(item);
                        updateenablegenEmp(item);
                        updateenablecollect(item);
                      }}
                      style={{ marginRight: "10px" }}
                    />
                    {item.applicant_name}
                  </Table.Cell>
                  <Table.Cell>
                    <div className="table-row-item">{item.assessed_on}</div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="table-row-item">
                      {item.recommended_occupation}
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="table-row-item">ACS</div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="table-row-item">
                      {item.recommended_pathway || ""}
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="table-row-item">{item.status}</div>
                  </Table.Cell>
                  <Table.Cell>
                    <div
                      className="table-row-item"
                      onClick={() => handledeleted(item.applicant_id)}
                    >
                      <Image src={delete_icon} avatar />
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell style={{ paddingLeft: "200px" }}>
                  <Oval
                    height={50}
                    width={50}
                    color="#0971ce"
                    wrapperStyle={{}}
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="gray"
                    strokeWidth={2}
                  />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    </>
  );
}

export default Documents;
