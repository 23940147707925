/* eslint-disable no-unused-vars */
/*******************************************************************************
* FileUpload: This components used for the file upload for resume and dropdown
  for selected the access for
* Contributors: Abhishek Kuamr Yadav
*******************************************************************************/
import React, { useState } from "react";
import { Dropdown, Button } from "semantic-ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStatus } from "../../statusContext";
import "semantic-ui-css/semantic.min.css";
import "../../css/index.css";
import "../../css/lexx.css";

function FileUpload(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { setStatus, setResult, setDocName, setDashStatus, setDocID } =
    useStatus();
  const [xmlFile, setXmlFile] = useState(null);
  const [filename, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const extension = file.name.split(".").pop();
      if (!["pdf", "doc", "docx"].includes(extension.toLowerCase())) {
        alert("Please choose a PDF, DOC, or DOCX file.");
        event.target.value = null; // Clear the file input
        setFileName(""); // Clear the file name
        setXmlFile(null); // Clear the file
        return;
      }
      const dname = file.name.split(".").slice(0, -1).join(".");
      setFileName(dname);
      setXmlFile(file);
      setDocName(dname);
      const reader = new FileReader();
      reader.onload = function (e) {
        const fileData = e.target.result;
        sessionStorage.setItem("uploadedFile", fileData);
        sessionStorage.setItem("filename", file.name);
        sessionStorage.setItem("type", file.type);
      };
      reader.readAsDataURL(file);
    }
  };
  

  const handleAbort = async () => {
    try {
      const response = await fetch(
        `https://api.xhorizons-dev.lexxtechnologies.com/abort/${location.state.applicant_id}`,
        { method: "POST" }
      );
      if (response.ok) {
        console.log("Abort successful");
        navigate("/");
      } else {
        console.error("Failed to abort:", response.statusText);
      }
    } catch (error) {
      console.error("There was an error aborting the process", error);
    } finally {
      // Add any necessary cleanup or UI changes after aborting
    }
  };

  const handleSubmit = async () => {
    setStatus("");
    setResult("");
    setIsLoading(true);
    const { state } = location;
    if (!xmlFile) {
      alert("Please choose a file to upload!");
      setIsLoading(false);
      return;
    }
    if (!state || !state.applicant_id) {
      console.error("Applicant ID is missing in the location state.");
      setIsLoading(false);
      alert(
        "The Processing has been interrupted. Please go to the dashboard and try again."
      );
      return; // Exit the function if applicant_id is missing
    }
    const formData = new FormData();
    formData.append("file", xmlFile);
    try {
      const response = await fetch(
        `https://api.xhorizons-dev.lexxtechnologies.com/upload/${state.applicant_id}`,
        {
          method: "POST",
          body: formData,
        }
      );
      if (response.ok) {
        const jsonData = await response.json();
        if (jsonData.applicant_id.length === 0) {
          throw new Error("Document ID is missing.");
        }
  
        setDocID(jsonData.applicant_id);
        const ws = new WebSocket(
          `wss://api.xhorizons-dev.lexxtechnologies.com/process/${jsonData.applicant_id}`
        );
  
        ws.onopen = () => {
          setStatus("");
          console.log("WebSocket connection opened.");
        };
  
        ws.onmessage = (event) => {
          const returned = JSON.parse(event.data);
          setStatus(returned.status);
          setDashStatus(returned.status);
  
          if (returned.status === "Assessed") {
            setResult(returned.result);
            setDashStatus("");
            setIsLoading(false);
          }
        };
  
        ws.onclose = () => {
          console.log("WebSocket connection closed.");
        };
      } else {
        console.error("Failed to fetch:", response.statusText);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("There was an error uploading the file", error);
      setIsLoading(false);
      alert(
        "The Processing has been interrupted. Please go to the dashboard and try again."
      );
    } finally {
      if (xmlFile) {
        setIsLoading(false);
        navigate("/submitresult", {
          state: { applicant_id: state.applicant_id },
        });
      }
    }
  };
  
  

  const options = [
    { key: 1, text: "Australian Computer Society", value: 1 },
    { key: 2, text: "Engineers Australia", value: 2 },
  ];

  return (
    <>
      <div className="upload-file0pdf">
        <p> Upload a resume here</p>
        <label htmlFor="formFile"></label>
        <input
          type="file"
          id="formFile"
          accept=".pdf,.doc,.docx"
          onChange={handleFileChange}
        />
      </div>

      <div className="access-for-resume">
        <p>Assess for</p>
        <Dropdown
          clearable
          options={options}
          selection
          button
          placeholder="Australian Computer Society"
        />
      </div>
      <div className="button-upload">
        <Button className="button-1" onClick={handleAbort}>
          Abort
        </Button>
        <Button
          className="button-1"
          onClick={handleSubmit}
          disabled={!xmlFile}
        >
          Assess
        </Button>
      </div>
    </>
  );
}

export default FileUpload;
