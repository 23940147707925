import React, { useState } from "react";
import { Menu, Image, Icon, Popup } from "semantic-ui-react";
import Shape from "../../images/icons8-menu-50.png";
import Technician from "../../images/technician.png";
import Sidebar from "../Sidebar/Sidebar";
import KeycloakUserService from "../login/KeyCloakService";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const showSidebar = () => {
    setShow(!show);
  };

  const handleLogout = () => {
    localStorage.removeItem("session");
    KeycloakUserService.doLogout();
  };

  const navigateToHome = () => {
    navigate("/");
  };

  return (
    <>
      <Sidebar show={show} setShow={setShow} />
      <Menu borderless fixed="top" className="header" id="menu">
        <Menu.Item className="menu-icon">
          <img
            onClick={showSidebar}
            className="search-icon"
            src={Shape}
            alt="icon logo"
          ></img>
        </Menu.Item>
        <Menu.Item onClick={navigateToHome} className="emigration-text">eMigration Advisor</Menu.Item>
        <Menu.Item position="right" className="menu-icon-btn">
          <Popup
            style={{ fontSize: "10px", background: "rgb(242 247 251) " ,height:"40px" ,textAlign:"center" }}
            trigger={
              <div
                style={{ marginRight: "1.45rem" }}
                onClick={() => navigate("/allNotifications")}
              >
                <Icon
                  name="inverted bell outline"
                  style={{ cursor: "pointer" }}
                />
              </div>
            }
            position="top center"
          >
            Notifications
          </Popup>
          <Popup
            style={{ fontSize: "10px", background: "rgb(242 247 251)" ,height:"40px" ,textAlign:"center"  }}
            trigger={
              <Icon
                onClick={handleLogout}
                name=" inverted  power off"
                style={{ cursor: "pointer" }}
              />
            }
            position="top center"
          >
            Logout
          </Popup>
          <Popup
            style={{ fontSize: "10px", background: "rgb(242 247 251)",height:"40px" ,textAlign:"center"  }}
            trigger={
              <div className="profile-wrapper">
                <Image
                  src={Technician}
                  circular
                  className="profile-image-holder"
                />
              </div>
            }
            position="top center"
          >
            My Profile
          </Popup>
        </Menu.Item>
      </Menu>
    </>
  );
};

export default Header;
